import React from "react";
import { useTranslation } from "react-i18next";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";

import { styled } from "@mui/material/styles";

import { makeStyles } from "@mui/styles";
import { sanitizeData } from "../utils/Common";
import { ObjectType } from "../types/tableTypes";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(158, 156, 156, 0.1)",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StrippedTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "rgba(158, 156, 156, 0.1)",
  },
  "&:nth-of-type(odd)": {
    backgroundColor: "#FAFCFF",
  },
}));

const FilledTableRow = styled(TableRow)(({ theme }) => ({
  borderRadius: "6px",
  background: "#F5F8FF",
}));

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "none",
  },
}));
export default function LeverageTable({
  tableHeader,
  tableBody,
  label,
  tableClassName,
}: {
  tableHeader: any;
  tableBody: ObjectType;
  label: string;
  tableClassName?: string;
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  function getResultText(key: string, parentKey: ObjectType, idx: number) {
    const className =
      (`${key}`?.includes("-") && !isNaN(+key)) ||
        (`${key}`?.includes("-") && `${key}`?.includes("%"))
        ? "pass-text"
        : (`${key}`?.includes("+") && !isNaN(+key)) ||
          (`${key}`?.includes("+") && `${key}`?.includes("%"))
          ? "failed-texts"
          : "";

    const showColouredTexts =
      (parentKey?.data?.length === 3 && parentKey?.data[1] === key) ||
      parentKey?.label === "requested";

    let keyToBeShown: string = key;
    if (Boolean(keyToBeShown) && /[+-]/.test(keyToBeShown)) {
      if (parentKey?.label?.toLowerCase() === "requested") {
        keyToBeShown = keyToBeShown?.replace(/[+-]/g, "")
      }
    }

    return (
      <span className={showColouredTexts ? className : ""}>
        {(`${keyToBeShown}`?.includes("%"))
          ? keyToBeShown
          : typeof keyToBeShown === "string" && idx !== 0
            ? sanitizeData(
              t(`${keyToBeShown}`),
              true,
              2
            )
            : sanitizeData(t(keyToBeShown), true)}
      </span>
    );
  }

  const getTableBody = () => {
    return tableBody
      ? !Array.isArray(tableBody[0])
        ? Object.keys(tableBody)?.map((key: string, idx: number) => {
          return (
            <StrippedTableRow>
              {tableBody[key]?.data?.map((data: any, index: number) => {
                return (
                  <StyledTableCell
                    style={{
                      color: "black",
                      borderBottom: "none",
                      marginBottom: "8px",
                      borderRight: "1px solid #D5DCED",
                    }}
                    key={index}
                    className={index === 0 ? tableBody[key].borderColor : ""}
                  >
                    {getResultText(data, tableBody[key], index)}
                  </StyledTableCell>
                );
              })}
            </StrippedTableRow>
          );
        })
        : tableBody?.map((body: any, index: number) => {
          return (
            <React.Fragment>
              <FilledTableRow
                key={index}
                style={
                  body[0] === "Final Price Adjustment"
                    ? { borderRadius: "6px", background: "#E4EAFF" }
                    : {}
                }
              >
                {body?.map((data: any, idx: number) => {
                  return (
                    <StyledTableCell
                      style={
                        idx == 0 || body.length === 3
                          ? {
                            color: "black",
                            borderBottom: "none",
                            marginBottom: "8px",
                          }
                          : {
                            color: "black",
                            borderBottom: "none",
                            marginBottom: "8px",
                            display: "flex",
                            justifyContent: "end",
                          }
                      }
                      key={idx}
                      sx={{ borderBottom: "none" }}
                    >
                      <span
                        style={
                          idx !== 0
                            ? {
                              color:
                                "var(--primary-primary-main-700, #40128B)",
                            }
                            : {}
                        }
                        className={
                          data &&
                            idx !== 0 &&
                            typeof data === "string" &&
                            data?.includes("-") &&
                            label !== "pricing"
                            ? "red-color"
                            : ""
                        }
                      >
                        {index === 0 &&
                          body[0] === "Final Price Adjustment" ? (
                          <b>{t(data)}</b>
                        ) : (
                          t(data)
                        )}
                      </span>
                    </StyledTableCell>
                  );
                })}
              </FilledTableRow>
            </React.Fragment>
          );
        })
      : null;
  };

  return (
    <Paper className={classes.paper}>
      <TableContainer
        sx={{
          padding: "0px 0px",
          borderRadius: "8px",
          fontSize: "1.1rem",
        }}
      >
        <Table className={tableClassName}>
          {tableHeader?.length ? (
            <TableHead
              style={{
                backgroundColor: "#9E9C9C1A",
              }}
            >
              <StrippedTableRow style={{ borderRadius: "8px" }}>
                {tableHeader?.map((ele: any, idx: number) => {
                  return (
                    <StyledTableCell
                      className={ele?.headerClassName ?? ""}
                      key={idx}
                    >
                      {t(ele?.label ?? ele)}
                    </StyledTableCell>
                  );
                })}
              </StrippedTableRow>
            </TableHead>
          ) : null}
          <TableBody>{getTableBody()}</TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
