import { Grid, IconButton } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { loanDataBasedOnLoanId } from "../../services/network";
import { RootContext } from "../../context/RootContext";
import { LoansContext } from "../../context/LoansContext";
import { useTranslation } from "react-i18next";
import { getCookie, sanitizeCurrency } from "../../utils/Common";
import { getSuggestedLoanTerms, getTermSheetData } from "./Common";
import { getTermSheetBasedOnVersion } from "../../services/termSheet";
import jwt_decode from "jwt-decode";
import moment from "moment";
import {
  TermSheetV2,
  DownloadTermSheet
} from "@toorak/op-web-common-service/src/index";
// import TermSheetV2 from "./v2/v2/termSheet";
// import DownloadTermSheet from "./v2/downloadTermSheet";

import CloseIcon from "../../assets/CloseIcon.svg";
import { useStyles } from "./termSheet.style";

const TermSheetCell = ({ label, rowData }: { label: any, rowData: any }) => {
  const { loanType, loanId, termSheetVersion } = rowData;
  const { t } = useTranslation();
  const accessToken = getCookie("sizer_access_token") || "";
  const decodedData: any = jwt_decode(accessToken);
  let originatorData: any =
    getCookie("originatorData") ||
    localStorage?.getItem("originatorData") ||
    "{}";
  originatorData = JSON.parse(originatorData);
  const classes = useStyles();
  const [emailData, setEmailData] = useState<any>(null);
  const [defaultFeeDetails, setDefaultFeeDetails] = useState<any>([]);
  const [isOpenDownloadTermSheet, setIsOpenDownloadTermSheet] = useState<any>(false);
  const [signatureData, setSignatureData] = useState<any>(null);

  const permissionsToRenderInUI = {
    showLoanDetails: true,
    showFeeDetails: true,
    showEmailSection: true,
    showEmailBody: true,
    isFullView: true,
    showMerchantsMortgage: false,
    showRequirements: true
  };

  const loanContextData = useContext(LoansContext);
  const {
    dynamicConfig,
    termSheetData,
    setTermSheetData,
    termSheetConfig,
    setTermSheetConfig,
    termSheetAction,
    setTermSheetAction,
    currentTermSheetVersion,
    setCurrentTermSheetVersion,
    isOpenTermSheet,
    setIsOpenTermSheet
  } = useContext(RootContext);
  const { setLoanDetails, setPropertyDetails, setFetchData } = loanContextData;

  const handleView = async () => {
    const loanData = await fetchLoanDataBasedOnLoanId();
    setCurrentTermSheetVersion(termSheetVersion);

    if (termSheetVersion) {
      const data = getTermSheetData(
        loanType,
        loanData,
        t,
        "regenerate",
        termSheetData,
        loanContextData?.defaultSelectedDataView
      );
      const newSuggestedLoanTerms = getSuggestedLoanTerms(
        loanType,
        loanData,
        t,
        termSheetData
      );
      const response = await fetchGetTermSheetBasedOnVersion(
        data,
        termSheetVersion
      );
      const newRequirements = response?.requirements;
      const newFeeDetails =
        response?.feeDetails ||
        JSON.parse(JSON.stringify(termSheetData?.feeDetails));
      const ccEmails = response?.email?.cc;
      const toEmails = response?.email?.to;
      const emailSentOn = response?.emailSentOn;
      const date = response?.email?.date ? moment(new Date(response?.email?.date)).format("MM/DD/YYYY") 
      : response.updatedAt ? moment(new Date(response.updatedAt)).format("MM/DD/YYYY") : ""
      setSignatureData(response?.signatureInfo ?? { name: decodedData?.name, email: decodedData?.email, accountName: originatorData?.accountName });
      setTermSheetData({
        emailSentOn,
        loanData: data,
        suggestedLoanTerms: newSuggestedLoanTerms,
        requirements: newRequirements,
        feeDetails: newFeeDetails,
        emails: { 
          fromEmail: [decodedData?.email], 
          toEmails, 
          ccEmails,
          subject: response?.email?.subject,
        },
        date,
        emailBody : response?.email?.content,
        currentTermSheetVersion: termSheetVersion,
        evaluationID: rowData?.evaluationId
      });
      updateTermSheetConfig(response?.signatureInfo);
    }
  };

  const fetchLoanDataBasedOnLoanId = async () => {
    const body = [loanId];
    const loanDataResponse = await loanDataBasedOnLoanId(body);
    const loanData = loanDataResponse?.data?.data[0]?.data;
    setFetchData(loanData);
    setPropertyDetails(loanData?.propertyDetails);
    setLoanDetails(loanData);
    return {
      loanDetails: loanData,
      propertyDetails: loanData?.propertyDetails,
      fetchData: loanData
    };
  };

  // get term sheet data based on version
  const fetchGetTermSheetBasedOnVersion = async (
    loanData: any,
    termSheetVersion = currentTermSheetVersion
  ) => {
    try {
      const realmHeaders = {
        "kc-realm": dynamicConfig?.ViteAppKcRealm
      };
      const response = await getTermSheetBasedOnVersion(
        realmHeaders,
        loanId,
        +termSheetVersion
      );
      return response?.data?.data;
    } catch (err) {
      console.error(err);
    }
  };

  const handleClose = () => {
    setTermSheetData(null);
    setIsOpenTermSheet(false);
    setTermSheetAction("");
  };

  const handleEmailChange = (toEmails: any, ccEmails: any, tempTermSheetData: any) => {
    const emails = {
      ...tempTermSheetData.emails,
      toEmails: [...toEmails],
      ccEmails: [...ccEmails]
    };
    setEmailData(emails);
    setTermSheetData({
      ...tempTermSheetData,
      emails: { ...tempTermSheetData?.emails, toEmails, ccEmails }
    });
  };

  const handleFeeChange = (e: any, key: any, feeDetailsData: any) => {
    const tempFeeDetails = {
      ...JSON.parse(JSON.stringify(feeDetailsData)),
      [key]: `${Number(e.target.value)}`
    };
    setTermSheetData({ ...termSheetData, feeDetails: tempFeeDetails });
  };

  const handleFeeBlur = (e: any, key: any, feeDetailsData: any) => {
    const tempFeeDetails = {
      ...JSON.parse(JSON.stringify(feeDetailsData)),
      [key]: `${Number(e).toFixed(2)}`
    };
    setTermSheetData({ ...termSheetData, feeDetails: tempFeeDetails });
  };

  // add new custom fee
  const handleAddNewFeeConfig = (addedFees: any, newFees: any, feeDetailsData: any) => {
    const tempFeeDetails = { ...JSON.parse(JSON.stringify(feeDetailsData)) };
    setDefaultFeeDetails([...addedFees]);
    setTermSheetData({
      ...termSheetData,
      feeDetails: { ...newFees, ...tempFeeDetails }
    });
  };

  // delete fee
  const handleDeleteFee = (key: any, feeDetailsData: any) => {
    const newFeeDetails = { ...JSON.parse(JSON.stringify(feeDetailsData)) };
    delete newFeeDetails[key];
    setTermSheetData({ ...termSheetData, feeDetails: newFeeDetails });
  };

  const cleanedAdressString = () => {
    const propertyLocation =
      loanContextData?.propertyDetails[0]?.propertyLocation;
    if (!propertyLocation) {
      return "";
    }
    const pattern = new RegExp(
      `\\b(${propertyLocation.postalCode}|${propertyLocation.city}|${propertyLocation.state})\\b`,
      "g"
    );

    // Remove matches from the address
    const cleanedAddress = propertyLocation.address
      .replace(pattern, "")
      .trim()
      .replace(/\s+/g, " ");
    const address = propertyLocation?.address
      ? `${cleanedAddress}, ${propertyLocation?.city}, ${propertyLocation?.state} ${propertyLocation?.postalCode}`
      : "";
    return address;
  };

  const handleDownloadPDF = () => {
    setIsOpenDownloadTermSheet(true);
  };

  const updateTermSheetConfig = (signatureInfo: any) => {
    const accessToken = getCookie("sizer_access_token") || "";
    const decodedData: any = jwt_decode(accessToken);

    const date = moment(new Date()).format("MM/DD/YYYY");
    const address = cleanedAdressString();
    const newData = {
      isViewMode: !["regenerate", "generate"].includes(termSheetAction),
      termSheetAction: 'view-from-list',
      header: {
        showOriginatorInfo: true,
        originatorInfo: `${originatorData?.accountName} - ID: ${rowData?.evaluationId
          } (${t("termSheetKeyForHeader")} v ${termSheetVersion ?? ""})`, // "ABC Capital - ID: 26356 (TermSheet v0.1)",
        className: classes?.termSheetHeader,
        closeIcon: CloseIcon
      },
      email: {
        subject: `Loan Proposal for ${address}`,
        date,
        fromEmail: decodedData?.email,
        handleEmailChange
      },
      feeDetails: {
        config: defaultFeeDetails,
        handleFeeChange,
        handleFeeBlur,
        handleDeleteFee,
        handleAddNewFeeConfig
      },
      footer: {
        onCloseModal: setIsOpenTermSheet,
        onSendEmail: () => { },
        name: decodedData?.name,
        handleDownloadPDF
      },
      signatureInfo: signatureData ?? signatureInfo
    };
    setTermSheetConfig(newData);
    setIsOpenTermSheet(true);
    setTermSheetAction("view-from-list");
  }

  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={10} style={{ overflow: "hidden" }}>
          <span
            className="text-truncate"
            title={label}
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              textWrap: "wrap"
            }}
          >
            {label}
          </span>
        </Grid>
        <Grid item xs={2}>
          <IconButton
            aria-label="redirect"
            sx={{ color: "blue" }}
            onClick={handleView}
          >
            <OpenInNewIcon sx={{ fontSize: 16 }} />
          </IconButton>
        </Grid>
      </Grid>
      {isOpenTermSheet ? (
        <TermSheetV2
          isOpenTermSheet={isOpenTermSheet}
          termSheetData={termSheetData}
          setTermSheetData={setTermSheetData}
          isUserConfig={false}
          permissionsToRenderInUI={permissionsToRenderInUI}
          // setIsOpenTermSheet={setIsOpenTermSheet}
          onClose={handleClose}
          termSheetConfig={termSheetConfig}
          // termSheetAction={termSheetAction}
          // isViewMode={true}
          sanitizeCurrency={sanitizeCurrency}
          isTermsheetAlreadySaved={false}
          emailBodyChange={() => {}}
        />
      ) : null}

      {isOpenDownloadTermSheet ? (
        <DownloadTermSheet
          termSheetData={termSheetData}
          setTermSheetData={setTermSheetData}
          isUserConfig={false}
          termSheetConfig={termSheetConfig}
          permissionsToRenderInUI={permissionsToRenderInUI}
          termSheetAction={termSheetAction}
          setIsOpenDownloadTermSheet={setIsOpenDownloadTermSheet}
          isOpenDownloadTermSheet={isOpenDownloadTermSheet}
          sanitizeCurrency={sanitizeCurrency}
        />
      ) : null}
    </>
  );
};

export default TermSheetCell;
