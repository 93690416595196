import { axiosClient, templateAxiosClient } from "../App";

export const generateNewTermSheet = (payload: any, loanId: any, realmHeaders: any) => {
  return axiosClient.post(`/online-sizer/term-sheet/${loanId}`, payload, {
    headers: realmHeaders
  });
};

export const updateLatestTermSheetData = (payload: any, loanId: any, realmHeaders: any, currentTermSheetVersion: any) => {
  return axiosClient.put(`/online-sizer/term-sheet/${loanId}/${currentTermSheetVersion}`, payload, {
    headers: realmHeaders
  });
};

export const getTermSheetVersions = (realmHeaders: any, loanId: any) => {
  return axiosClient.get(`/online-sizer/term-sheet/${loanId}`, {
    headers: realmHeaders
  });
}

export const getTermSheetBasedOnVersion = (realmHeaders: any, loanId: any, version: number) => {
  return axiosClient.get(`/online-sizer/term-sheet/${loanId}/${version}`, {
    headers: realmHeaders
  });
};

export const sendTermSheetEmail = (payload: any, headers: any, clientConfig: any) => {
  return templateAxiosClient.post("/term-sheet/notification/template/pricing_template/originator/pricing-term-sheet", payload, {
    headers
  });
}

export const addNewFieldConfig = (payload: any, headers: any) => {
  return axiosClient.post("/public-api/accountmap/available-term-sheet-dynamic-field", payload, {
    headers
  });
}

export const getFieldConfigBasedOnOriginator = (originatorId: any, headers: any) => {
  return axiosClient.get(`/public-api/accountmap/originator/${originatorId}`, {
    headers,
  });
}

export const updateFieldConfigBasedOnOriginator = (originatorId: any, payload:any, headers: any) => {
  return axiosClient.patch(`/public-api/accountmap/originator/${originatorId}`, payload, {
    headers,
  });
}