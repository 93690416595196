import React from "react";

import Grid from "@mui/material/Grid";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import DownArrow from "../../../assets/DownArrow.svg";
import InfoIcon from "../../../assets/InfoIcon.svg";

import { styled } from "@mui/material/styles";

import { ObjectType } from "../../../types/tableTypes";
import CustomChip from "../CustomChip";
import CustomCard from "../BasicCustomCard";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  background: "none",
  border: "none",
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<img src={DownArrow} style={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  borderRadius: "6px",
  border: "1px solid #DBE2FF",
  background: "rgba(228, 234, 255, 0.80)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  "& MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiCard-root makeStyles-card-3":
    {
      marginBottom: "12px",
    },
}));

export default function LoanResultTab({
  tabData,
  isExpanded,
  expandLeverageAndTests,
  setIsExpanded,
  loanDetails,
  isLoanDSCR,
  ratesAsOfDate
}: {
  tabData: any;
  isExpanded: any;
  expandLeverageAndTests: boolean;
  setIsExpanded: any;
  loanDetails: ObjectType;
  isLoanDSCR?: boolean;
  ratesAsOfDate?: string;
}) {
  const contentDiv = {
    padding: "12px",
    borderRadius: "6px",
    background: "white",
  };

  const gridSize = Math.floor(12 / tabData?.length);
  return (
    <div>
      <Grid
        container
        spacing={expandLeverageAndTests ? 0 : 1}
        className="mt-12"
        style={
          expandLeverageAndTests
            ? { overflowX: "auto", flexWrap: "nowrap" }
            : {}
        }
      >
        {tabData?.map((test: ObjectType, idx: number) => {
          const currentTabKey = Object.keys(test)[0];
          return !expandLeverageAndTests ? (
            <Grid item xs={12} key={idx}>
              {isLoanDSCR ? (
                <Accordion
                  expanded={isExpanded[currentTabKey]}
                  onChange={() => {
                    setIsExpanded({
                      ...isExpanded,
                      [currentTabKey]: !isExpanded[currentTabKey],
                    });
                  }}
                  data-testid="accordion-button"
                >
                  <AccordionSummary>
                    <h3>{Object.keys(test)[0]}</h3>
                    <CustomChip
                      size="small"
                      label={
                        <div className="div-space-between">
                          <img
                            src={InfoIcon}
                            alt="info-icon"
                            className="mr-8"
                          />
                          {`Failed - ${test[Object.keys(test)[0]]?.length}`}
                        </div>
                      }
                      variant="outlined"
                      className="error-chipData"
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    {test[Object.keys(test)[0]]?.map(
                      (testEle: ObjectType, idx: number) => {
                        return (
                          <CustomCard
                            key={idx}
                            headingText={testEle.title}
                            subHeadingText={testEle.subText}
                            loanDetails={loanDetails}
                            fullView={true}
                            ratesAsOfDate={ratesAsOfDate}
                            gridSpace={testEle?.gridSpace ?? 4}
                            showFailed={true}
                          />
                        );
                      }
                    )}
                  </AccordionDetails>
                </Accordion>
              ) : (
                <CustomCard
                  key={idx}
                  headingText={test.title}
                  subHeadingText={test.subText}
                  loanDetails={loanDetails}
                  fullView={true}
                  ratesAsOfDate={ratesAsOfDate}
                  gridSpace={test?.gridSpace ?? 4}
                  showFailed={true}
                />
              )}
            </Grid>
          ) : (
            <Grid
              item
              xs={gridSize <= 4 ? 5 : gridSize}
              style={{
                borderRight: "1px solid #A9A9A9",
                minWidth: "400px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "6px 0px 0px 0px",
                  border: "1px solid #DBE2FF",
                  background: "rgba(228, 234, 255, 0.80)",
                  padding: "0px 10px",
                }}
              >
                <h3>{isLoanDSCR ? currentTabKey : test.title}</h3>
                <CustomChip
                  size="small"
                  label={
                    <div className="div-space-between">
                      <img src={InfoIcon} alt="info-icon" className="mr-8" />{" "}
                      {isLoanDSCR
                        ? `Failed - ${test[currentTabKey]?.length}`
                        : "Failed"}
                    </div>
                  }
                  variant="outlined"
                  className="error-chipData"
                  data-testid="custom-chip"
                />
              </div>
              <div>
                {isLoanDSCR ? (
                  test[currentTabKey]?.map(
                    (testEle: ObjectType, idx: number) => {
                      return (
                        <div key={idx} style={contentDiv}>
                          <CustomCard
                            headingText={testEle.title}
                            subHeadingText={testEle.subText}
                            loanDetails={loanDetails}
                            fullView={true}
                            ratesAsOfDate={ratesAsOfDate}
                            gridSpace={testEle?.gridSpace ?? 4}
                            cardCss={true}
                          />
                        </div>
                      );
                    }
                  )
                ) : (
                  <CustomCard
                    key={idx}
                    headingText={test.title}
                    subHeadingText={test.subText}
                    loanDetails={loanDetails}
                    fullView={true}
                    ratesAsOfDate={ratesAsOfDate}
                    gridSpace={test?.gridSpace ?? 4}
                    showFailed={true}
                  />
                )}
              </div>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
