const getBridgeLoanType = (fetchData: any, propertyDetails: any) => {
  let returnStr = "BridgeLoan";
  if (propertyDetails[0]?.propertyInformation?.propertyType === "Multifamily" && ["Stabilized", "Fix & Flip"].includes(fetchData?.loanType)) {
    returnStr = "BridgeLoanMultifamily";
  } else if (fetchData?.loanType === "Ground Up") {
    returnStr = "BridgeLoanGroundUp";
  } else {
    returnStr = "BridgeLoan24"
  }
  return returnStr;
};

const sanitizeCurrency = (value: any) => {
  const data = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  }).format(value);

  return data;
};

export const getTermSheetData = (loanType: string, loanData: any, t: any, termSheetAction: string, termSheetData: any, defaultSelectedDataView: string) => {
  const isViewOrDownload = ["view", "download"].includes(termSheetAction);
  const isMinDataView = defaultSelectedDataView === "minimumDataView";

  const { loanDetails, propertyDetails, fetchData, autoCalculatedLoanResults, leverageAndPricingData, rulesResponse } = loanData;
  const loanId = loanData?.loansApiResponse?.loanId;
  const maxAsIsLtv = rulesResponse?.loanResult?.[loanId]?.maxLeverage?.maxAsIsLTV && !isNaN(+rulesResponse?.loanResult?.[loanId]?.maxLeverage?.maxAsIsLTV) ? `${+rulesResponse?.loanResult?.[loanId]?.maxLeverage?.maxAsIsLTV * 100} %` : "--";

  const loanTypeStr = loanType === "Investor DSCR" ? "Investor DSCR" : getBridgeLoanType(fetchData, propertyDetails);
    switch (loanTypeStr) {
      case "Investor DSCR":
        return [
          [
            { key: "loanAmount", label: `Total Loan Amount`, value: (isViewOrDownload ? sanitizeCurrency(termSheetData?.termSheetLoanInfo?.loanAmount) : sanitizeCurrency(loanDetails?.loanEconomics?.borrowerRequested)) || "--" },
            { key: "cashOutFlag", label: `If Applicable- Estimated Cash Out`, value: isViewOrDownload ? termSheetData?.termSheetLoanInfo?.cashOutFlag : loanDetails?.loanInformation?.cashOutFlag ? `${loanDetails?.loanInformation?.cashOutRefinance}` : "--" },
            { key: "maxLTV", label: `Max LTV based on borrower stated value of $xxxx.  Subject to full UW review`, value: isViewOrDownload ? termSheetData?.termSheetLoanInfo?.maxLTV : maxAsIsLtv },
            { key: "dscr", label: `Minimum DSCR`, value: (isViewOrDownload ? termSheetData?.termSheetLoanInfo?.dscr : isMinDataView ? loanDetails?.loanEconomics?.dscr : autoCalculatedLoanResults?.loanEconomics?.dscr) || "--" },
            { key: "payOfFBalance", label: `If Applicable- PayOff Balance on Existing Loan(s)`, value:  "--" },
            { key: "interestRate", label: "Note Rate", value: isViewOrDownload ? termSheetData?.termSheetLoanInfo?.interestRate : loanDetails?.loanEconomics?.originalInterestRate ? `${loanDetails?.loanEconomics?.originalInterestRate} %` : "--" },
            { key: "fico", label: `Based on Current FICO`, value:  isViewOrDownload ? termSheetData?.termSheetLoanInfo?.fico : loanDetails?.borrowerInformation?.creditScoreActual},
            { key: "propertyType", label: t("propertyType"), value: (isViewOrDownload ? termSheetData?.termSheetLoanInfo?.propertyType : propertyDetails[0]?.propertyInformation?.propertyType) ?? "--" },
            { key: "propertyUsage", label: "Property Usage", value: (isViewOrDownload ? termSheetData?.termSheetLoanInfo?.propertyUsage : propertyDetails[0]?.unitInformation[0]?.currentUnitUsage) || "Investment Property" },
            { key: "minNoOfMonthsReserve", label: `Estimated Minimum Reserves`, value: isViewOrDownload ? termSheetData?.termSheetLoanInfo?.minNoOfMonthsReserve : leverageAndPricingData?.requiredReserves?.minimumRequiredReserves },
          ],
          [
            { key: "loanTerm", label: t("loanTerm"), value: isViewOrDownload ? (termSheetData?.termSheetLoanInfo?.loanTerm ? `${termSheetData?.termSheetLoanInfo?.loanTerm}`: "--") : loanDetails?.loanInformation?.loanTerm ? `${loanDetails?.loanInformation?.loanTerm} Months` : "--" },
            { key: "ioPeriod", label: `Interest-Only Period`, value: (isViewOrDownload ? termSheetData?.termSheetLoanInfo?.interestOnlyPeriod: `${loanDetails?.loanEconomics?.ioPeriod} Months`)|| "--" },
            { key: "lien", label: `Lien`, value:  "First Lien" },
            { key: "rentalCharacterization", label: `Rental Status`, value: isViewOrDownload ? termSheetData?.termSheetLoanInfo?.rentalCharacterization : `${propertyDetails[0]?.unitInformation[0]?.rentalCharacterization}` || "--" },
            { key: "prePaymentPenalty", label: `Pre-Payment Penalty`, value:  isViewOrDownload ? termSheetData?.termSheetLoanInfo?.prePaymentPenalty : `${loanDetails?.loanEconomics?.prepaymentPenaltyTypeMonths} Months - ${loanDetails?.loanEconomics?.prepayPenaltyType}` || "--" },
            { key: "loanType", label: `Loan Type`, value:  isViewOrDownload ? termSheetData?.termSheetLoanInfo?.loanType : loanTypeStr || "--" },
            { key: "loanPurpose", label: `Purpose`, value:  isViewOrDownload ? termSheetData?.termSheetLoanInfo?.loanPurpose : `${loanDetails?.loanInformation?.loanPurpose}` || "--" },
            { key: "citizenshipStatus", label: t("citizenshipStatus"), value: isViewOrDownload ? termSheetData?.termSheetLoanInfo?.citizenshipStatus : loanDetails?.borrowerInformation?.borrowerGuarantor ? (loanDetails?.borrowerInformation?.borrowerGuarantor === "Yes" ? loanDetails?.borrowerInformation?.immigrationStatus : "U.S. Citizen") : "--" },
          ]
        ];
        case "BridgeLoan24":
          return [
            [
              { key: "baseProgram", label: t("baseProgram"), value: isViewOrDownload ? termSheetData?.termSheetLoanInfo?.baseProgram : loanDetails?.productType? `${loanDetails?.productType} - ${loanDetails?.propertyType}` : `${fetchData?.productType} - ${fetchData?.propertyType}` },
              { key: "loanTerm", label: t("loanTerm"), value: isViewOrDownload ? termSheetData?.termSheetLoanInfo?.loanTerm : loanDetails?.loanInformation?.loanTerm ? `${loanDetails?.loanInformation?.loanTerm} Months` : "12 Months" },
              { key: "loanPurpose", label: t("loanPurpose"), value: isViewOrDownload ? termSheetData?.termSheetLoanInfo?.loanPurpose : loanDetails?.loanInformation?.loanPurpose ? `${loanDetails?.loanInformation?.loanPurpose}` : "--" },
              { key: "propertyType", label: t("propertyType"), value: isViewOrDownload ? termSheetData?.termSheetLoanInfo?.propertyType : propertyDetails[0]?.propertyInformation?.propertyType ?? "--" },
              { key: "guarantorsExperience", label: t("guarantorsExperience"), value: isViewOrDownload ? termSheetData?.termSheetLoanInfo?.guarantorsExperience : loanDetails?.borrowerInformation?.propertiesCompletedWithinLast36Months ?? "--" },
              { key: "citizenshipStatus", label: t("citizenshipStatus"), value: loanDetails?.borrowerInformation?.borrowerGuarantor ? (loanDetails?.borrowerInformation?.borrowerGuarantor === "Yes" ? loanDetails?.borrowerInformation?.immigrationStatus : "U.S. Citizen") : "--" },
              { key: "creditScore", label: t("creditScore"), value: isViewOrDownload ? termSheetData?.termSheetLoanInfo?.creditScore : loanDetails?.borrowerInformation?.originalCreditScore ?? "--" },
            ],
            [
              { key: "zipCode", label: t("zipCode"), value: isViewOrDownload ? termSheetData?.termSheetLoanInfo?.zipCode : propertyDetails[0]?.propertyLocation ? `${propertyDetails[0]?.propertyLocation?.city, propertyDetails[0]?.propertyLocation?.state, propertyDetails[0]?.propertyLocation?.postalCode}` : "--" },
              { key: "asIsValue", label: t("asIsValueLabel"), value: isViewOrDownload ? sanitizeCurrency(termSheetData?.termSheetLoanInfo?.asIsValue) : propertyDetails[0]?.propertyEconomics?.orgAppraisalValue? sanitizeCurrency(propertyDetails[0]?.propertyEconomics?.orgAppraisalValue) : "--" },
              { key: "purchasePrice", label: t("purchasePriceLabel"), value: isViewOrDownload ? sanitizeCurrency(termSheetData?.termSheetLoanInfo?.purchasePrice)  : propertyDetails[0]?.propertyEconomics?.purchasePrice? sanitizeCurrency(propertyDetails[0]?.propertyEconomics?.purchasePrice) : "--" },
              { key: "rehabCosts", label: t("rehabCosts"), value: isViewOrDownload ? sanitizeCurrency(termSheetData?.termSheetLoanInfo?.rehabCosts) : loanDetails?.loanEconomics?.totalRehabBudget? sanitizeCurrency(loanDetails?.loanEconomics?.totalRehabBudget) : "--" },
              { key: "completedRehab", label: t("completedRehab"), value: "--" },
              { key: "afterRepairValueARV", label: t("afterRepairValueARV"), value: isViewOrDownload ? sanitizeCurrency(termSheetData?.termSheetLoanInfo?.afterRepairValueARV) : propertyDetails[0]?.propertyEconomics?.asRepairedValueARV ? sanitizeCurrency(propertyDetails[0]?.propertyEconomics?.asRepairedValueARV) : "--" },
            ],
          ];
          case "BridgeLoanGroundUp":
          return [
            [
              { key: "baseProgram", label: t("baseProgram"), value: isViewOrDownload ? termSheetData?.termSheetLoanInfo?.baseProgram : loanDetails?.productType? `${loanDetails?.productType} - ${loanDetails?.propertyType}` : `${fetchData?.productType} - ${fetchData?.propertyType}` },
              { key: "loanTerm", label: t("loanTerm"), value: isViewOrDownload ? termSheetData?.termSheetLoanInfo?.loanTerm : loanDetails?.loanInformation?.loanTerm ? `${loanDetails?.loanInformation?.loanTerm} Months` : "12 Months" },
              { key: "loanPurpose", label: t("loanPurpose"), value: isViewOrDownload ? termSheetData?.termSheetLoanInfo?.loanPurpose : loanDetails?.loanInformation?.loanPurpose ? `${loanDetails?.loanInformation?.loanPurpose}` : "--" },
              { key: "propertyType", label: t("propertyType"), value: isViewOrDownload ? termSheetData?.termSheetLoanInfo?.propertyType : propertyDetails[0]?.propertyInformation?.propertyType ?? "--" },
              { key: "guarantorsExperience", label: t("guarantorsExperience"), value: isViewOrDownload ? termSheetData?.termSheetLoanInfo?.guarantorsExperience : loanDetails?.borrowerInformation?.propertiesCompletedWithinLast36Months ?? "--" },
              { key: "citizenshipStatus", label: t("citizenshipStatus"), value: isViewOrDownload ? termSheetData?.termSheetLoanInfo?.citizenshipStatus : loanDetails?.borrowerInformation?.borrowerGuarantor ? (loanDetails?.borrowerInformation?.borrowerGuarantor === "Yes" ? loanDetails?.borrowerInformation?.immigrationStatus : "U.S. Citizen") : "--" },
              { key: "creditScore", label: t("creditScore"), value: isViewOrDownload ? termSheetData?.termSheetLoanInfo?.creditScore : loanDetails?.borrowerInformation?.originalCreditScore ?? "--" },
            ],
            [
              { key: "zipCode", label: t("zipCode"), value: isViewOrDownload ? termSheetData?.termSheetLoanInfo?.zipCode : propertyDetails[0]?.propertyLocation ? `${propertyDetails[0]?.propertyLocation?.city, propertyDetails[0]?.propertyLocation?.state, propertyDetails[0]?.propertyLocation?.postalCode}` : "--" },
              { key: "asIsValue", label: t("asIsValueLabel"), value: isViewOrDownload ? sanitizeCurrency(termSheetData?.termSheetLoanInfo?.asIsValue) : propertyDetails[0]?.propertyEconomics?.orgAppraisalValue? sanitizeCurrency(propertyDetails[0]?.propertyEconomics?.orgAppraisalValue) : "--" },
              { key: "purchasePrice", label: t("purchasePriceLabel"), value: isViewOrDownload ? sanitizeCurrency(termSheetData?.termSheetLoanInfo?.purchasePrice)  : propertyDetails[0]?.propertyEconomics?.purchasePrice? sanitizeCurrency(propertyDetails[0]?.propertyEconomics?.purchasePrice) : "--" },
              { key: "rehabCosts", label: t("rehabCosts"), value: isViewOrDownload ? sanitizeCurrency(termSheetData?.termSheetLoanInfo?.rehabCosts) : loanDetails?.loanEconomics?.totalRehabBudget? sanitizeCurrency(loanDetails?.loanEconomics?.totalRehabBudget) : "--" },
              { key: "completedRehab", label: t("completedRehab"), value: "--" },
              { key: "afterRepairValueARV", label: t("afterRepairValueARV"), value: isViewOrDownload ? sanitizeCurrency(termSheetData?.termSheetLoanInfo?.afterRepairValueARV) : propertyDetails[0]?.propertyEconomics?.asRepairedValueARV ? sanitizeCurrency(propertyDetails[0]?.propertyEconomics?.asRepairedValueARV) : "--" },
            ],
          ];
          case "BridgeLoanMultifamily":
            return [
              [
                { key: "baseProgram", label: t("baseProgram"), value: isViewOrDownload ? termSheetData?.termSheetLoanInfo?.baseProgram : loanDetails?.productType? `${loanDetails?.productType} - ${loanDetails?.propertyType}` : `${fetchData?.productType} - ${fetchData?.propertyType}` },
                { key: "loanTerm", label: t("loanTerm"), value: isViewOrDownload ? termSheetData?.termSheetLoanInfo?.loanTerm : loanDetails?.loanInformation?.loanTerm ? `${loanDetails?.loanInformation?.loanTerm} Months` : "12 Months" },
                { key: "loanPurpose", label: t("loanPurpose"), value: isViewOrDownload ? termSheetData?.termSheetLoanInfo?.loanPurpose : loanDetails?.loanInformation?.loanPurpose ? `${loanDetails?.loanInformation?.loanPurpose}` : "--" },
                { key: "propertyType", label: t("propertyType"), value: isViewOrDownload ? termSheetData?.termSheetLoanInfo?.propertyType : propertyDetails[0]?.propertyInformation?.propertyType ?? "--" },
                { key: "guarantorsExperience", label: t("guarantorsExperience"), value: isViewOrDownload ? termSheetData?.termSheetLoanInfo?.guarantorsExperience : loanDetails?.borrowerInformation?.propertiesCompletedWithinLast36Months ?? "--" },
                { key: "citizenshipStatus", label: t("citizenshipStatus"), value: isViewOrDownload ? termSheetData?.termSheetLoanInfo?.citizenshipStatus : loanDetails?.borrowerInformation?.borrowerGuarantor ? (loanDetails?.borrowerInformation?.borrowerGuarantor === "Yes" ? loanDetails?.borrowerInformation?.immigrationStatus : "U.S. Citizen") : "--" },
                { key: "creditScore", label: t("creditScore"), value: isViewOrDownload ? termSheetData?.termSheetLoanInfo?.creditScore : loanDetails?.borrowerInformation?.originalCreditScore ?? "--" },
              ],
              [
                { key: "zipCode", label: t("zipCode"), value: isViewOrDownload ? termSheetData?.termSheetLoanInfo?.zipCode : propertyDetails[0]?.propertyLocation ? `${propertyDetails[0]?.propertyLocation?.city, propertyDetails[0]?.propertyLocation?.state, propertyDetails[0]?.propertyLocation?.postalCode}` : "--" },
                { key: "asIsValue", label: t("asIsValueLabel"), value: isViewOrDownload ? sanitizeCurrency(termSheetData?.termSheetLoanInfo?.asIsValue) : propertyDetails[0]?.propertyEconomics?.orgAppraisalValue? sanitizeCurrency(propertyDetails[0]?.propertyEconomics?.orgAppraisalValue) : "--" },
                { key: "purchasePrice", label: t("purchasePriceLabel"), value: isViewOrDownload ? sanitizeCurrency(termSheetData?.termSheetLoanInfo?.purchasePrice)  : propertyDetails[0]?.propertyEconomics?.purchasePrice? sanitizeCurrency(propertyDetails[0]?.propertyEconomics?.purchasePrice) : "--" },
                { key: "rehabCosts", label: t("rehabCosts"), value: isViewOrDownload ? sanitizeCurrency(termSheetData?.termSheetLoanInfo?.rehabCosts) : loanDetails?.loanEconomics?.totalRehabBudget? sanitizeCurrency(loanDetails?.loanEconomics?.totalRehabBudget) : "--" },
                { key: "completedRehab", label: t("completedRehab"), value: "--" },
                { key: "afterRepairValueARV", label: t("afterRepairValueARV"), value: isViewOrDownload ? sanitizeCurrency(termSheetData?.termSheetLoanInfo?.afterRepairValueARV) : propertyDetails[0]?.propertyEconomics?.asRepairedValueARV ? sanitizeCurrency(propertyDetails[0]?.propertyEconomics?.asRepairedValueARV) : "--" },
              ],
            ];
      default:
        break;
    }
}

export const getSuggestedLoanTerms = (loanType: string, loanData: any, t: any, termSheetAction: string, termSheetData?: any) => {
  const isViewOrDownload = ["view", "download"].includes(termSheetAction);
  const { loanDetails, propertyDetails, autoCalculatedLoanResults, fetchData } = loanData;
  const leverageAndPricingData = loanData?.leverageAndPricingData || loanData?.loanDetails?.leverageAndPricingData
  // const tempMaxLoanAmount = leverageAndPricingData?.proceeds?.tableBody?.length && 
  //                           leverageAndPricingData?.proceeds?.tableBody[0]?.data[leverageAndPricingData?.proceeds?.tableBody[0]?.data?.length - 1];
  // let tempAmountRequested = leverageAndPricingData?.proceeds?.tableBody?.length &&
  //                           leverageAndPricingData?.proceeds?.tableBody[2]?.data[leverageAndPricingData?.proceeds?.tableBody[2]?.data?.length - 1];
  // tempAmountRequested = tempAmountRequested && typeof tempAmountRequested === "string" ? `${tempAmountRequested}`?.replace(/-/g, "") : tempAmountRequested;
  const tableBody = leverageAndPricingData?.proceeds?.tableBody;

  const tempMaxLoanAmount = tableBody?.[0]?.data?.slice(-1)[0];
  let tempAmountRequested = tableBody?.[2]?.data?.slice(-1)[0];

  if (tempAmountRequested && typeof tempAmountRequested === "string") {
      tempAmountRequested = tempAmountRequested.replace(/-/g, "");
  }
  const maxLoanAmount: any = !isNaN(Number((tempMaxLoanAmount))) && typeof Number(tempMaxLoanAmount) === "number" ? sanitizeCurrency(tempMaxLoanAmount) : tempMaxLoanAmount; 
  const amountRequested: any = !isNaN(Number((tempAmountRequested))) && typeof Number(tempAmountRequested) === "number" ? sanitizeCurrency(tempAmountRequested) : tempAmountRequested; 

  const loanTypeStr = loanType === "Investor DSCR" ? "Investor DSCR" : getBridgeLoanType(fetchData, propertyDetails);
    switch (loanTypeStr) {
        case "BridgeLoan24":
          return [
            [
              { key: "loanAmount", label: "Initial Amount", value: isViewOrDownload ? sanitizeCurrency(termSheetData?.termSheetLoanInfo?.loanAmount) : sanitizeCurrency(loanDetails?.loanEconomics?.initialLoanAmount) || "--" },
              { key: "maxLoanAmount", label: t("maxLoanAmount"), value: isViewOrDownload ? sanitizeCurrency(termSheetData?.termSheetLoanInfo?.maxLoanAmount) : leverageAndPricingData?.proceeds ? maxLoanAmount : "--" },
              { key: "amountRequested", label: t("amountRequested"), value: isViewOrDownload ? sanitizeCurrency(termSheetData?.termSheetLoanInfo?.amountRequested) :leverageAndPricingData?.proceeds ? amountRequested : "--" },
            ],
            [
              { key: "interestRate", label: "Note Rate", value: isViewOrDownload ? termSheetData?.termSheetLoanInfo?.interestRate : loanDetails?.loanEconomics?.originalInterestRate ? `${loanDetails?.loanEconomics?.originalInterestRate} %` : "--" },
              { key: "interestType", label: t("interestType"), value: "Fixed" },
              { key: "rehabBudgetLenderFunded", label: t("rehabBudgetLenderFunded"), value: isViewOrDownload ? sanitizeCurrency(termSheetData?.termSheetLoanInfo?.rehabBudgetLenderFunded) : loanDetails?.loanEconomics?.financedBudget ? sanitizeCurrency(loanDetails?.loanEconomics?.financedBudget) : "--" },
            ]
          ];
          case "BridgeLoanGroundUp":
          return [
            [
              { key: "loanAmount", label: "Initial Amount", value: isViewOrDownload ? termSheetData?.termSheetLoanInfo?.loanAmount : sanitizeCurrency(loanDetails?.loanEconomics?.initialLoanAmount) || "--" },
              { key: "maxLoanAmount", label: t("maxLoanAmount"), value: isViewOrDownload ? sanitizeCurrency(termSheetData?.termSheetLoanInfo?.maxLoanAmount) : leverageAndPricingData?.proceeds ?   maxLoanAmount : "--" },
              { key: "amountRequested", label: t("amountRequested"), value: isViewOrDownload ? sanitizeCurrency(termSheetData?.termSheetLoanInfo?.amountRequested) :leverageAndPricingData?.proceeds ? amountRequested : "--" },
            ],
            [
              { key: "interestRate", label: "Note Rate", value: isViewOrDownload ? termSheetData?.termSheetLoanInfo?.interestRate : loanDetails?.loanEconomics?.originalInterestRate ? `${loanDetails?.loanEconomics?.originalInterestRate} %` : "--" },
              { key: "interestType", label: t("interestType"), value: "Fixed" },
              { key: "rehabBudgetLenderFunded", label: t("rehabBudgetLenderFunded"), value: isViewOrDownload ? sanitizeCurrency(termSheetData?.termSheetLoanInfo?.rehabBudgetLenderFunded) : loanDetails?.loanEconomics?.financedBudget ? sanitizeCurrency(loanDetails?.loanEconomics?.financedBudget) : "--" },
            ]
          ];
          case "BridgeLoanMultifamily":
            return [
              [
                { key: "loanAmount", label: "Initial Amount", value: isViewOrDownload ? sanitizeCurrency(termSheetData?.termSheetLoanInfo?.loanAmount) : sanitizeCurrency(loanDetails?.loanEconomics?.initialLoanAmount) || "--" },
                { key: "maxLoanAmount", label: t("maxLoanAmount"), value: isViewOrDownload ? sanitizeCurrency(termSheetData?.termSheetLoanInfo?.maxLoanAmount):  leverageAndPricingData?.proceeds ?  maxLoanAmount : "--" },
                { key: "amountRequested", label: t("amountRequested"), value: isViewOrDownload ? sanitizeCurrency(termSheetData?.termSheetLoanInfo?.amountRequested) :leverageAndPricingData?.proceeds ? amountRequested : "--" },
              ],
              [
                { key: "interestRate", label: "Note Rate", value: isViewOrDownload ? termSheetData?.termSheetLoanInfo?.interestRate : loanDetails?.loanEconomics?.originalInterestRate ? `${loanDetails?.loanEconomics?.originalInterestRate} %` : "--" },
                { key: "interestType", label: t("interestType"), value: "Fixed" },
                { key: "rehabBudgetLenderFunded", label: t("rehabBudgetLenderFunded"), value: isViewOrDownload ? sanitizeCurrency(termSheetData?.termSheetLoanInfo?.rehabBudgetLenderFunded) : loanDetails?.loanEconomics?.financedBudget ? sanitizeCurrency(loanDetails?.loanEconomics?.financedBudget) : "--" },
              ]
            ];
      default:
        break;
    }
}
